<template>
    <div class="animated fadeIn">
        <h1>Kategorien</h1>
        <div class="row align-items-center float-right col-3">
            <router-link style="margin-bottom: 10px" :to="'/property/editCategory/-1'" tag="button" class="btn margin-bottom btn-primary btn-block">Kategorien anlegen</router-link>
        </div>
        <div id="Categories">
            <v-server-table ref="table" :columns="columns" :options="options">
                <template slot="id" slot-scope="props">
                    <router-link :to="'/property/editCategory/'+props.row.id.toString()">
                        <a class="fa fa-edit fa-lg"></a>
                    </router-link>
                    &nbsp;
                    <a class="fa fa-trash fa-lg text-primary" @click="warningModal = true, selectedCategory = props.row.id.toString()" style="cursor: pointer"></a>
                </template>
            </v-server-table>
        </div>


        <!-- Modal Component -->
        <b-modal title="Kategorie löschen?" class="modal-warning" v-model="warningModal" @ok="deleteCategory()" ok-variant="warning">
            Wollen Sie die Kategorie wirklich löschen?
        </b-modal>

    </div>
</template>

<script>
    import Vue from 'vue'
    import {ServerTable} from 'vue-tables-2'
    import VueNotifications from 'vue-notifications'

    Vue.use(ServerTable)

    const axios = require('axios')

    export default {
        name: 'categories',
        data () {
            return {
                warningModal: false,
                selectedCategory: 0,
                nrOfEntries: 0,
                columns: ['name', 'id'],
                options: {
                    requestFunction: function(data, store) {
                        return axios.get(this.$store.state.baseUrl + 'category', { headers: { 'X-AUTH-TOKEN': this.$store.state.authObj.token } }).then((response)=> {
                            this.nrOfEntries = response.data.length
                            let pagedResult = {
                                data: [],
                            }
                            for (let i = ((data.page-1) * data.limit); i < (data.page * data.limit); i++){
                                if(i < response.data.length) {
                                    pagedResult.data.push(response.data[i])
                                }
                            }
                            return pagedResult
                        }).catch(function (e){
                            if(e.response.status == 403){
                                this.$store.commit('setAuthObj', { authObj: null }, { root: true })
                            } else {
                                this.dispatch('error', e);
                            }
                            return {
                                data: [],
                                count: 0,
                            }
                        }.bind(this))
                    },
                    responseAdapter({data}) {
                        return {
                            data,
                            count: this.nrOfEntries
                        }
                    },
                    headings: {
                        id: '',
                    },
                    //filterByColumn: true,
                    filterable: false,
                    sortable: ['name'],
                    sortIcon: {
                        base: 'fa',
                        up: 'fa-sort-up',
                        down: 'fa-sort-down',
                        is: 'fa-sort'
                    },
                    texts: {
                        //count: 'Showing {from} to {to} of {count} records|{count} records|One record - Test',
                        count: '',
                        filterPlaceholder: 'Search query',
                    },
                    pagination: {
                        nav: 'scroll'
                    },
                    perPage: 10
                }
            }
        },
        methods: {
            deleteCategory() {
                axios.delete(this.$store.state.baseUrl + 'category/' + this.selectedCategory, { headers: { 'X-AUTH-TOKEN': this.$store.state.authObj.token } })
                    .then((response)=>{
                        this.$refs.table.refresh()
                    }).catch(error => {
                        if(error.response.status == 403){
                            this.$store.commit('setAuthObj', { authObj: null }, { root: true })
                        } else if(error.response.status == 409){
                            this.notifyForeignKeyError()
                        } else {
                            this.notifySaveError()
                        }
                    })
            },
        },
        notifications: {
            notifyForeignKeyError: {
                type: VueNotifications.types.error,
                title: 'Fehler beim löschen der Kategorie',
                message: 'Die Kategorie ist in Verwendung'
            },
            notifySaveError: {
                type: VueNotifications.types.error,
                title: 'Fehler beim löschen der Kategorie',
            }
        }
    }
</script>
